import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IConfiguration } from "./types/_configuration";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/system/configs';
const configurationApi = {
  configurations(params: any):Promise<AxiosResponse<ResponseData<IConfiguration[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  postConfiguration(dataForm: any) : Promise<AxiosResponse<ResponseData<IConfiguration>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putConfiguration(key: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IConfiguration>, any>>  {
    const url = `${path}/${key}`;
    return api.put(url, dataForm)
  },
  deleteConfiguration(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IConfiguration>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
}
export default configurationApi
