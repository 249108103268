import { Option } from "api/types/_public";


export const ARR_INDEX_TEXT_DAY_OF_WEEK:string[] = ['Sunday', 'Monday', 'Tuesday',  'Wednesday', 'Thursday', 'Friday', 'Saturday'];


export const SCHEDULE_OPTIONS: Option[] = [
  {
    label: "Now",
    value: "now",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const SCHEDULE_MONTHLY_OPTIONS: Option[] = Array.from(
  { length: 31 },
  (_, index) => {
    const day = (index + 1).toString().padStart(2, "0");
    return { label: `${day}th`, value: day };
  }
);

export const SCHEDULE_WEEKLY_OPTIONS: Option[] = [
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
  {
    label: "Sunday",
    value: "7",
  },
];

export const TYPE_SHOW_MORE_HASHTAG = {
  SCROLL: 'scroll',
  MODAL: 'modal'
}


export const STATUS_MEDIA_OPTIONS: Option[] = [
  {
    label: "Status_Media_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Media_1", // Active
    value: "1",
  }
];


export const COLORS_CONFIGURATION_STATUS = ["danger", "success"];

export const COLORS_STATUS_CAMPAIGN:any = {
  pending: "secondary", 
  approved: "primary", 
  completed: "success", 
  rejected: "danger"
};




export const PLATFORM_LOG_OPTIONS: Option[] = [
  {
    label: "Platform_naver",
    value: "naver",
  },
  {
    label: "Platform_google",
    value: "google",
  },
  {
    label: "Platform_etc",
    value: "etc",
  },
  
];

export const AD_PLATFORM_LOG_OPTIONS: Option[] = [
  {
    label: "Platform_ad_system",
    value: "ad_system",
  },
  {
    label: "Platform_coupang",
    value: "coupang",
  },
];

export const AD_TYPE_OPTIONS: Option[] = [
  {
    label: "Type_close",
    value: "close",
  },
  {
    label: "Type_click",
    value: "click",
  },
  {
    label: "Type_impression",
    value: "impression",
  },
  {
    label: "Type_request",
    value: "request",
  },
];


export const DEVICE_OPTIONS: Option[] = [
  {
    label: "Device_pc",
    value: "pc",
  },
  {
    label: "Device_mobile",
    value: "mobile",
  },
];

export const BOT_OPTIONS: Option[] = [
  {
    label: "Bot_0",
    value: '0',
  },
  {
    label: "Bot_1",
    value: '1',
  },
];

export const BROWSER_OPTIONS: Option[] = [
  {
    label: "Browser_Chrome",
    value: 'Chrome',
  },
  {
    label: "Browser_Safari",
    value: 'Safari',
  },
  {
    label: "Browser_Unknown_Browser",
    value: 'Unknown Browser',
  },
];


export const IS_OPTIONS: Option[] = [
  {
    label: "YES",
    value: "1",
  },
  {
    label: "NO",
    value: "0",
  },
];




export const OS_OPTIONS: Option[] = [
  {
    label: "OS_iOS",
    value: 'iOS',
  },
  {
    label: "OS_Mac",
    value: 'Mac',
  },
  {
    label: "OS_Windows",
    value: 'Windows',
  },
  {
    label: "OS_Android",
    value: 'Android',
  },
  {
    label: "OS_Etc",
    value: 'Etc',
  },
];


export const TYPE_NEWS_OPTIONS: Option[] = [
  {
    label: "Type_News_1", // Trend News
    value: "1",
  },
  {
    label: "Type_News_0", // Media News
    value: "0",
  },
  {
    label: "Type_News_2", // Not News
    value: "2",
  },
];