import { createSlice } from "@reduxjs/toolkit";
import { IInventory } from "api/types/_inventory";
import { IMedia } from "api/types/_media";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { reportInventories, reportMedias } from "./thunk";

export interface IState {
  medias: ResponseData<IMedia[]> & PaginationResponse | null,
  isMediaLoading: boolean,
  isMediaSuccess: boolean,

  inventories: ResponseData<IInventory[]> & PaginationResponse | null,
  isInventoryLoading: boolean,
  isInventorySuccess: boolean,

  error: any,
};

export const initialState: IState = {
  medias: null,
  isMediaLoading: false,
  isMediaSuccess: false,

  inventories: null,
  isInventoryLoading: false,
  isInventorySuccess: false,

  error: {},
};

const ReportSlice = createSlice({
  name: "Report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //report medias
    builder.addCase(reportMedias.pending, (state: IState, action: any) => {
      state.isMediaLoading = true
    });
    builder.addCase(reportMedias.fulfilled, (state: IState, action: any) => {
      state.medias = action.payload.data;
      state.isMediaSuccess = true;
      state.isMediaLoading = false;
    });
    builder.addCase(reportMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaSuccess = false;
      state.isMediaLoading = false;
    });

    //report Inventories
    builder.addCase(reportInventories.pending, (state: IState, action: any) => {
      state.isInventoryLoading = true
    });
    builder.addCase(reportInventories.fulfilled, (state: IState, action: any) => {
      state.inventories = action.payload.data;
      state.isInventorySuccess = true;
      state.isInventoryLoading = false;
    });
    builder.addCase(reportInventories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isInventorySuccess = false;
      state.isInventoryLoading = false;
    });
  },
});

export default ReportSlice.reducer;