import { createAsyncThunk } from "@reduxjs/toolkit";
import mediaApi from 'api/mediaApi';
import inventoryApi from 'api/inventoryApi';
import { formatQueryParams } from "helpers/format";

export const getMedias = createAsyncThunk("Medias" , async (params: any = {}) => {
  try{
    const response = await mediaApi.medias(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});


export const getInventories = createAsyncThunk("Inventories" , async (params: any = {}) => {
  try{
    const response = await inventoryApi.inventories(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

export const getMedia = async (id: number) => {
  try{
    const response = await mediaApi.getMedia(id)
    return response;
  }catch (error) {
    return error;
  }
};

export const postMedia = async (data: any = {}) => {
  try{
    const response = await mediaApi.postMedia(data)
    return response;
  }catch (error) {
    return error;
  }
};

export const putMedia = async (id:number, data: any = {}) => {
  try{
    const response = await mediaApi.putMedia(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteMedia = async (id:number, data: any = {}) => {
  try{
    const response = await mediaApi.deleteMedia(id, data);
    return response;
  }catch (error) {
    return error;
  }
};


export const getAllMedias =  async(params: any = {}) => {
  try{
    const response = await mediaApi.allMedias(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const getInventory = async (id: number) => {
  try{
    const response = await inventoryApi.getInventory(id)
    return response;
  }catch (error) {
    return error;
  }
};

export const postInventory = async (data: any = {}) => {
  try{
    const response = await inventoryApi.postInventory(data)
    return response;
  }catch (error) {
    return error;
  }
};

export const putInventory = async (id:number, data: any = {}) => {
  try{
    const response = await inventoryApi.putInventory(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteInventory = async (id:number, data: any = {}) => {
  try{
    const response = await inventoryApi.deleteInventory(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const uploadImage = async (data: any = {}) => {
  try{
    const response = await inventoryApi.uploadImage(data);
    return response;
  }catch (error) {
    return error;
  }
};