import { IMedia } from 'api/types/_media';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllMedias, getTrackingLogsByNormal, getTrackingSummariesLogs } from "../../../store/thunks";
import { CONFIG_OPTION_TOAST_ERROR } from 'common/toast';
import CopyWrapper from 'components/Common/CopyWrapper';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownOption from 'components/Common/DropdownOption';
import LabelWrapper from 'components/Common/LabelWrapper';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useDurationResponses } from 'components/Hooks/DurationResponsesHooks';
import { useRole } from 'components/Hooks/UserHooks';
import { AD_PLATFORM_LOG_OPTIONS, AD_TYPE_OPTIONS, BOT_OPTIONS, BROWSER_OPTIONS, DEVICE_OPTIONS, IS_OPTIONS, OS_OPTIONS, PLATFORM_LOG_OPTIONS, TYPE_NEWS_OPTIONS } from 'helpers/constans';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import CountUp from "react-countup";
import { ITracking } from 'api/types/_tracking';
import DropdownMedia from 'components/Common/DropdownMedia';
import { formatLinkHTTPS } from 'helpers/format';
interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'createdAt';


const TrackingLog = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const { durationResponses, setDurationResponses } = useDurationResponses();

  const isFirstLoadingPageRef = useRef<any>(true);

  const PLATFORM_LOG_OPTIONS_LANG = PLATFORM_LOG_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const AD_PLATFORM_LOG_OPTIONS_LANG = AD_PLATFORM_LOG_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const AD_TYPE_OPTIONS_LANG = AD_TYPE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const DEVICE_OPTIONS_LANG = DEVICE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const BOT_OPTIONS_LANG = BOT_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const BROWSER_OPTIONS_LANG = BROWSER_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const OS_OPTIONS_LANG = OS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const TYPE_NEWS_OPTIONS_LANG = TYPE_NEWS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const IS_OPTIONS_LANG = IS_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    domain: withDefault(StringParam, ''),
    title: withDefault(StringParam, ''),
    reference_link: withDefault(StringParam, ''),
    ip: withDefault(StringParam, ''),
    og_url: withDefault(StringParam, ''),
    keyword: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    platform: withDefault(StringParam, ''),
    ad_platform: withDefault(StringParam, ''),
    ad_type: withDefault(StringParam, ''),
    device: withDefault(StringParam, ''),
    browser: withDefault(StringParam, ''),
    os: withDefault(StringParam, ''),
    bot: withDefault(StringParam, '0'),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([moment(query?.start_date || "", 'Y-MM-DD').toDate(), moment(query?.end_date || "", 'Y-MM-DD').toDate()]);
  const [startDate, endDate] = dateSearch;

  const [referenceLinkSearch, setReferenceLinkSearch] = useState<string>(query?.reference_link || "");
  const [ipSearch, setIpSearch] = useState<string>(query?.ip || "");

  const [urlSearch, setUrlSearch] = useState<string>(query?.og_url || "");

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [mediaSearch, setMediaSearch] = useState<Option | null>({
    label: listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
    value: `${listMedia?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
  });

  const [platformSearch, setPlatformSearch] = useState<Option | null>(PLATFORM_LOG_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.platform))[0]);

  const [adPlatformSearch, setAdPlatformSearch] = useState<Option | null>(AD_PLATFORM_LOG_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.ad_platform))[0]);

  const [adTypeSearch, setAdTypeSearch] = useState<Option | null>(AD_TYPE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.ad_type))[0]);

  const [deviceSearch, setDeviceSearch] = useState<Option | null>(DEVICE_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.device))[0]);

  const [botSearch, setBotSearch] = useState<Option | null>(BOT_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.bot))[0]);

  const [browserSearch, setBrowserSearch] = useState<Option | null>(BROWSER_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.browser))[0]);

  const [osSearch, setOsSearch] = useState<Option | null>(OS_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.os))[0]);

  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);

  const [previewTrackingLog, setPreviewTrackingLog] = useState<ITracking | null>(null);

  // Inside your component

  const [trackingLogs, setTrackingLogs] = useState<{ list: ITracking[], total: number }>({ list: [], total: 0 });

  const [isTrackingLogLoading, setIsTrackingLogLoading] = useState<boolean>(false);

  const [summariesLogs, setSummariesLogs] = useState<{ total_uv: number, total_keyword: number, unique_keyword: number }>({ total_uv: 0, total_keyword: 0, unique_keyword: 0 });
  const [isSummariesLogLoading, setIsSummariesLogLoading] = useState<boolean>(false);

  const handleQueryData = async () => {
    setIsTrackingLogLoading((prev) => true);
    if (isFirstLoadingPageRef?.current === true && query?.page !== 1) {
      setQuery({
        ...query,
        page: 1
      });
      return;
    }
    const res: any = await getTrackingLogsByNormal(query);

    setTrackingLogs((prev: any) => ({
      list: (prev?.list || []).concat(res?.data?.list || []),
      total: res?.data?.pagination?.total || 0,
    }));
    setIsTrackingLogLoading((prev) => false);
    setDurationResponses([{
      name: 'Media Log',
      time: res?.data?.pagination?.duration || 0
    }]);
  };

  const handleQuerySummariesData = async () => {
    isFirstLoadingPageRef.current = false;
    setIsSummariesLogLoading((prev) => true);
    const res: any = await getTrackingSummariesLogs(query);
    setSummariesLogs((prev: any) => ({
      total_uv: res?.data?.total_uv || 0,
      total_keyword: res?.data?.total_keyword || 0,
      unique_keyword: res?.data?.unique_keyword || 0,
    }));
    setIsSummariesLogLoading((prev) => false);
    setDurationResponses([{
      name: 'Summaries Log',
      time: res?.duration || 0
    }]);
  };

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1
    });
  }

  useEffect(() => {
    handleQueryData();
    (query?.page === 1) && handleQuerySummariesData();
  }, [JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      title: titleSearch || "",
      reference_link: encodeURIComponent(referenceLinkSearch) || "",
      ip: ipSearch || "",
      og_url: encodeURIComponent(urlSearch) || '',
      keyword: keywordSearch || "",
      domain: mediaSearch?.value ? listMedia?.filter((item: any) => Number(item?.id) === Number(mediaSearch?.value))[0]?.website || mediaSearch?.value : '',
      platform: platformSearch?.value || '',
      ad_platform: adPlatformSearch?.value || '',
      ad_type: adTypeSearch?.value || '',
      device: deviceSearch?.value || '',
      bot: botSearch?.value ?? '',
      browser: browserSearch?.value ?? '',
      os: osSearch?.value ?? '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    };

    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setTrackingLogs((prev: any) => ({
        list: [],
        total: 0,
      }));
      setSummariesLogs((prev: any) => ({
        total_uv: 0,
        total_keyword: 0,
        unique_keyword: 0
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      title: '',
      reference_link: '',
      ip: '',
      og_url: '',
      keyword: '',
      domain: '',
      sort_by: TYPE_SELECT_DEFAULT,
      start_date: moment(new Date()).format("Y-MM-DD"),
      end_date: moment(new Date()).format("Y-MM-DD"),
      platform: '',
      ad_platform: '',
      ad_type: '',
      device: '',
      bot: '',
      browser: '',
      os: '',
      page: 1,
      time_request: + new Date()
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setTrackingLogs((prev: any) => ({
        list: [],
        total: 0,
      }));
      setSummariesLogs((prev: any) => ({
        total_uv: 0,
        total_keyword: 0,
        unique_keyword: 0
      }));
    }
    setQuery(queryNew, "push");
    setReferenceLinkSearch((_prev) => "");
    setIpSearch((_prev) => "");
    setUrlSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setTitleSearch((_prev) => "");
    setMediaSearch({ label: t('All Media'), value: '' });
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
    setPlatformSearch(null);
    setAdPlatformSearch(null);
    setAdTypeSearch(null);
    setDeviceSearch(null);
    setBotSearch(null);
    setBrowserSearch(null);
    setOsSearch(null);
  };

  function onClosePreviewClick() {
    setIsOpenPreview((_prev) => !_prev);
  }

  function onClosePreviewTrackingLogClick() {
    setPreviewTrackingLog((_prev) => null);
  }

  const handlePreviewTrackingLog = async (TrackingLog: ITracking | null = null) => {
    try {
      setPreviewTrackingLog((_prev) => TrackingLog);
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };


  const formatDate = (strDate: string = '') => {
    return `${String(strDate).split('T')[0]} ${String(String(strDate).split('T')[1]).split('.')[0]}`;
  }
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Title Post'),
        accessor: "title",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ maxWidth: '30dvw' }}>
              <CopyWrapper contentCopy={item?.og_title}>
                <Link className='text-normal' to={item?.og_url} target="_blank">
                  {item?.og_title}
                </Link>
              </CopyWrapper>
              {/* <div>
                <span className="text-dark">{t('Url')}: </span>
                <CopyWrapper contentCopy={item?.domain_url}>
                  <Link className='text-normal' to={item?.domain_url} target="_blank">
                    {item?.domain_url}
                  </Link>
                </CopyWrapper>
              </div>
              <div>
                <span className="text-dark">{t('WEB_reference_link')}: </span>
                <CopyWrapper contentCopy={item?.reference_link || ''}>
                  <Link className='text-normal ' to={item?.reference_link} target="_blank">
                    {item?.reference_link || ''}
                  </Link>
                </CopyWrapper>
              </div> */}
            </div>
          </>)
        },
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: false,
        thWidth: 160,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <CopyWrapper contentCopy={cell?.value}>
              {cell?.value}
            </CopyWrapper>
          </>)
        },
      },
      {
        Header: t('Media'),
        accessor: "domain",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const media = listMedia?.filter((item: any) => String(item?.website) === String(cell?.value))[0] || null;
          return (<>
            <div className="cursor-pointer">
              {cell?.value && (<> <span>
                {media?.name || ''}
              </span>
                <br />
                {!!media && <Link className='text-normal' to={formatLinkHTTPS(cell?.value)} target="_blank">
                  {cell?.value}
                </Link>}
              </>
              )}
            </div>
          </>)
        },
      },
      {
        Header: t('Ad Platform'),
        accessor: "ad_platform",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center',
        Cell: (cell: any) => (
          <>
            <div className="text-center" style={{ minWidth: '100px' }}>{AD_PLATFORM_LOG_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Ad Type'),
        accessor: "ad_type",
        filterable: true,
        sortable: false,
        thWidth: 100,
        thClass: 'text-center',
        Cell: (cell: any) => (
          <>
            <div className="text-center" style={{ minWidth: '100px' }}>{AD_TYPE_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Device'),
        accessor: "device",
        filterable: true,
        sortable: false,
        thWidth: 70,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '70px' }}>{DEVICE_OPTIONS_LANG?.filter((item) => (!!cell?.value && String(item?.value) === String(cell?.value)))[0]?.label || cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('OS'),
        accessor: "os",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '80px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Browser'),
        accessor: "browser",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('IP'),
        accessor: "ip",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: 'text-end',
        Cell: (cell: any) => {
          return (
            <div className="text-end">
              <span><span>{String(cell?.value || '').split(' ')[0] || ''}</span> <br /> <span className="text-secondary">{String(String(cell?.value || '').split(' ')[1]) || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Action'),
        thClass: 'text-center',
        thWidth: 60,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div>
              <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
                <TooltipCustom
                  title={t('Detail Media Log')}
                  id={`detail-log-${item?.id}`}
                >
                  <li className=" list-inline-item me-0" id={`detail-log-${item?.id}`} >
                    <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                      onClick={(e) => { e.preventDefault(); handlePreviewTrackingLog(item) }}
                    >
                      <i className="ri-eye-fill align-bottom"></i>
                    </Link>
                  </li>
                </TooltipCustom>
              </ul>
            </div>
          );
        },
      },
    ],
    [i18n?.language, JSON.stringify(listMedia)]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);


  const handleCallAllOption = async () => {
    try {
      const [resMediaLog]: any = await Promise.all([getAllMedias()]);
      const list = ([{ name: t('All Media'), id: '', website: '' } as any]).concat(resMediaLog?.data || []);
      setListMedia((_prev) => list);
      setMediaSearch((_prev) => ({
        label: list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.name || t('All Media'),
        value: `${list?.filter((item) => String(item?.website || '') === String(query?.domain || ''))[0]?.id || ''}`
      }));

    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (platformSearch) {
      setPlatformSearch((_prev: any) =>
        PLATFORM_LOG_OPTIONS_LANG?.filter((e: any) => e.value === platformSearch?.value)[0]
      );
    }

    if (adPlatformSearch) {
      setAdPlatformSearch((_prev: any) =>
        AD_PLATFORM_LOG_OPTIONS_LANG?.filter((e: any) => e.value === adPlatformSearch?.value)[0]
      );
    }

    if (adTypeSearch) {
      setAdTypeSearch((_prev: any) =>
        AD_TYPE_OPTIONS_LANG?.filter((e: any) => e.value === adTypeSearch?.value)[0]
      );
    }

    if (deviceSearch) {
      setDeviceSearch((_prev: any) =>
        DEVICE_OPTIONS_LANG?.filter((e: any) => e.value === deviceSearch?.value)[0]
      );
    }

    if (botSearch) {
      setBotSearch((_prev: any) =>
        BOT_OPTIONS_LANG?.filter((e: any) => e.value === botSearch?.value)[0]
      );
    }

    if (mediaSearch) {
      setListMedia((_prev) => (_prev || [])?.map((item) => item?.id ? item : ({ ...item, name: t('All Media') })));
      (!mediaSearch?.value) && setMediaSearch((prev) => ({ label: t('All Media'), value: '' }));
    }

    if (browserSearch) {
      setBrowserSearch((_prev: any) =>
        BROWSER_OPTIONS_LANG?.filter((e: any) => e.value === browserSearch?.value)[0]
      );
    }

    if (osSearch) {
      setOsSearch((_prev: any) =>
        OS_OPTIONS_LANG?.filter((e: any) => e.value === osSearch?.value)[0]
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Tracking Log')} - ${t('Reports')} | AdsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Tracking Log')} pageTitle={t('Reports')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.MEDIA_LOG_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-start mb-2 mt-2">
                      <div className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics mt-0 mt-md-3" style={{ maxWidth: '200px' }}>
                        <Card className="card-animate mb-0 me-0 me-md-4 mb-0 mb-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {(isTrackingLogLoading && query?.page === 1) ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={trackingLogs?.total || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card className="card-animate mb-0 me-0 me-md-4 mb-0 mb-md-1 mt-1 bg-primary-subtle text-primary order-1 order-md-0 border-0"> {/*  bỏ mt-1 */}
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('UV')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.total_uv || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        {/* <Card className="card-animate mb-0 me-0 me-md-4 mt-0 mt-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Keyword PV')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.total_keyword || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card> */}
                        {/* <Card className="card-animate mb-0 me-0 me-md-4 mt-0 mt-md-1 bg-primary-subtle text-primary order-1 order-md-0 border-0">
                          <CardBody>
                            <div className="d-flex align-items-center ">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Keyword UV')}</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                                  <span className="counter-value text-primary">
                                    {isSummariesLogLoading ? <Spinner size="sm" ></Spinner> : (
                                      <CountUp
                                        start={0}
                                        end={summariesLogs?.unique_keyword || 0}
                                        duration={1}
                                      />
                                    )}
                                  </span></h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card> */}
                      </div>
                      <div className="w-100">
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Device')} isShow={!!deviceSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={DEVICE_OPTIONS_LANG}
                                placeholder={`${t("Device")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={deviceSearch || null}
                                onChangeSelect={(e: any) => setDeviceSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Device'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('WEB_bot')} isShow={!!botSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={BOT_OPTIONS_LANG}
                                placeholder={`${t("WEB_bot")}...`}
                                className="search-filter-category-type dropdown-status-rounded "
                                classNamePrefix="name-prefix"
                                initialValue={botSearch || null}
                                onChangeSelect={(e: any) => setBotSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Type Bot'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Platform')} isShow={!!platformSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={PLATFORM_LOG_OPTIONS_LANG}
                                placeholder={`${t("Platform")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={platformSearch || null}
                                onChangeSelect={(e: any) => setPlatformSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Platform'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className="mt-3 mt-md-2 date-picker-wrapper-custom">
                            <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                              <DatePickerCustom
                                startDate={startDate || null}
                                endDate={endDate || null}
                                onChangePicker={handleChangePicker}
                              />
                            </LabelWrapper>
                          </Col>

                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('WEB_os')} isShow={!!osSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={OS_OPTIONS_LANG}
                                placeholder={`${t("WEB_os")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={osSearch || null}
                                onChangeSelect={(e: any) => setOsSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All OS'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>

                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('WEB_browser')} isShow={!!browserSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={BROWSER_OPTIONS_LANG}
                                placeholder={`${t("WEB_browser")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={browserSearch || null}
                                onChangeSelect={(e: any) => setBrowserSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Browser'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Ad Platform')} isShow={!!adPlatformSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={AD_PLATFORM_LOG_OPTIONS_LANG}
                                placeholder={`${t("Ad Platform")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={adPlatformSearch || null}
                                onChangeSelect={(e: any) => setAdPlatformSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Ad Platform'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Ad Type')} isShow={!!adTypeSearch?.value}>
                              <DropdownOption
                                name=""
                                dataList={AD_TYPE_OPTIONS_LANG}
                                placeholder={`${t("Ad Type")}...`}
                                className="search-filter-category-type dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={adTypeSearch || null}
                                onChangeSelect={(e: any) => setAdTypeSearch(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t('All Ad Type'), value: '' }}
                              />
                            </LabelWrapper>
                          </Col>

                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={12} lg={3} className='mt-2'>
                            <LabelWrapper label={t('IP')} isShow={!!ipSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('IP')}...`}
                                value={ipSearch}
                                onChange={(e) => setIpSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Keyword')}...`}
                                value={keywordSearch}
                                onChange={(e) => setKeywordSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Url')} isShow={!!urlSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Url')}...`}
                                value={urlSearch}
                                onChange={(e) => setUrlSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={3} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('Media')} isShow={!!mediaSearch?.value}>
                              <DropdownMedia
                                name="medias"
                                dataList={listMedia}
                                initialValue={mediaSearch}
                                onChangeSelect={(event: any) => {
                                  setMediaSearch((_prev) => event);
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={12} lg={6} className='mt-2'>
                            <LabelWrapper label={t('Title Post')} isShow={!!titleSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('Title Post')}...`}
                                value={titleSearch}
                                onChange={(e) => setTitleSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={6} className='mt-3 mt-md-2'>
                            <LabelWrapper label={t('WEB_reference_link')} isShow={!!referenceLinkSearch}>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t('WEB_reference_link')}...`}
                                value={referenceLinkSearch}
                                onChange={(e) => setReferenceLinkSearch(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </LabelWrapper>
                          </Col>
                          <Col sm={12} lg={12} className="hstack gap-1 justify-content-sm-center justify-content-md-end mt-4 mt-md-3">
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isTrackingLogLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t('Button Search')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t('Button Reset')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                  </CardHeader>
                  <CardBody className="pt-0 px-0">
                    <div>
                      <InfiniteScroll
                        dataLength={trackingLogs?.list?.length || 0}
                        next={handleLoadMore}
                        scrollableTarget='scrollableDiv'
                        hasMore={trackingLogs && trackingLogs?.list?.length < trackingLogs?.total ? true : false}
                        loader={''} // loader={<LoadingListNotify />}
                        scrollThreshold={'50%'}
                      >
                        <div className="mx-3 my-4">
                          <TableContainer
                            className="custom-header-css"
                            divClass="table-card"
                            tableClass="align-middle"
                            theadClass="table-light"
                            columns={columns}
                            data={trackingLogs?.list?.length ? trackingLogs?.list : []}
                            customPageSize={query.limit}
                            customPageIndex={query.page - 1}
                            totalRecords={trackingLogs?.total}
                            customPageCount={1}
                            handleChangePage={handleChangePage}
                            manualSorting={true}
                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                            handleChangeSorting={handleChangeSorting}
                            isLoading={isTrackingLogLoading}
                            isShowPagination={false}
                            isShowLoadingBottom={query.page > 1}
                            isScrollToTop={false}
                          />
                        </div>
                      </InfiniteScroll>
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal isOpen={!!previewTrackingLog} centered={true} size="xl" scrollable={true} toggle={onClosePreviewTrackingLogClick} keyboard={true}>
          <ModalHeader toggle={onClosePreviewTrackingLogClick}>
            {t('Detail Tracking Log')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <div className="tab-content border border-bottom-0">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              {Object.entries(previewTrackingLog || {}).map(([key, value]) => (
                                <tr key={key}>
                                  <th scope="row">{t(`WEB_${key}`)}</th>
                                  <td>
                                    {
                                      (String(key)?.includes('url') || String(key)?.includes('reference_link') || String(key)?.includes('image') || String(key)?.includes('site') || String(key)?.includes('domain')) ? (
                                        <Link className='text-normal' to={(String(value).includes('https://') || String(value).includes('http://')) ? value : `https://${value}`} target="_blank">
                                          {value || ''}
                                        </Link>
                                      ) : (
                                        <span>{(String(key)?.includes('atedAt') || String(key)?.includes('time')) ? formatDate(value) : (
                                          String(key)?.includes('bot') ? (BOT_OPTIONS_LANG?.filter((item) => (!!value && String(item?.value) === String(value)))[0]?.label || value) :
                                            (PLATFORM_LOG_OPTIONS_LANG?.filter((item) => (!!value && String(item?.value) === String(value)))[0]?.label || value)
                                        )}</span>
                                      )
                                    }
                                  </td>
                                </tr>
                              )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onClosePreviewTrackingLogClick}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal >
      </div >
    </React.Fragment >
  );
};

export default TrackingLog;
