import { createSlice } from "@reduxjs/toolkit";
import { getConfigurations } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IConfiguration } from "api/types/_configuration";


export interface IState {
  configurations: ResponseData<IConfiguration[]> & PaginationResponse | null,
  isConfigurationLoading: boolean,
  isConfigurationSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  configurations: null,
  isConfigurationLoading: false,
  isConfigurationSuccess: false,

  error: {},
};

const ConfigurationSlice = createSlice({
  name: "Configurations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Configurations
    builder.addCase(getConfigurations.pending, (state: IState, action: any) => {
      state.isConfigurationLoading = true
    });
    builder.addCase(getConfigurations.fulfilled, (state: IState, action: any) => {
      state.configurations = action.payload.data;
      state.isConfigurationSuccess = true;
      state.isConfigurationLoading = false;
    });
    builder.addCase(getConfigurations.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isConfigurationSuccess = false;
      state.isConfigurationLoading = false;
    });
  },
});

export default ConfigurationSlice.reducer;