import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";

//pages
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
import Settings from '../pages/Pages/Profile/Settings/Settings';

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile

import Profile from "pages/User/Profile";
import UserProfile from "../pages/Authentication/user-profile";

import RoleList from "pages/User/Role";
import UserList from "pages/User/User";

import Configuration from "pages/Configuration";

import ManagementMedia from "pages/Managements/Media";
import ManagementInventory from "pages/Managements/Inventory";

import ReportMedia from "pages/Reports/Media";
import ReportInventory from "pages/Reports/Inventory";
import TrackingLog from "pages/Tracking/TrackingLog";

const ROUTES = {
  DASHBOARD: '/dashboard',

  PROFILE: '/profile',
  PROFILE_SETTING: '/pages-profile-settings',

  USER_LIST: '/user/list',
  USER_ROLE: '/user/role',
  USER_PROFILE: '/user/profile',

  CONFIGURATION: '/configuration',

  MANAGEMENT_MEDIA: '/managements/media',
  MANAGEMENT_INVENTORY: '/managements/inventory',

  REPORT_MEDIA: '/reports/media',
  REPORT_INVENTORY: '/reports/inventory',

  TRACKING_LOG: '/reports/tracking-log'

}

const authProtectedRoutes = [
  { path: ROUTES.DASHBOARD, component: <DashboardCrm /> },

  { path: ROUTES.PROFILE_SETTING, component: <Settings /> },
  { path: ROUTES.PROFILE, component: <UserProfile /> },

  { path: ROUTES.USER_LIST, component: <UserList /> },
  { path: ROUTES.USER_ROLE, component: <RoleList /> },
  { path: ROUTES.USER_PROFILE, component: <Profile /> },

  { path: ROUTES.CONFIGURATION, component: <Configuration /> },

  { path: ROUTES.MANAGEMENT_MEDIA, component: <ManagementMedia /> },
  { path: ROUTES.MANAGEMENT_INVENTORY, component: <ManagementInventory /> },

  { path: ROUTES.REPORT_MEDIA, component: <ReportMedia /> },
  { path: ROUTES.REPORT_INVENTORY, component: <ReportInventory /> },

  { path: ROUTES.TRACKING_LOG, component: <TrackingLog /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { ROUTES, authProtectedRoutes, publicRoutes };

