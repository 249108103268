import { IMedia } from "api/types/_media";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { postMedia, putMedia } from "store/thunks";

import * as Yup from "yup";
import SVGImageDefault from "../Images/SVGImageDefault";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IMedia | null;
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const FormMedia = ({
    isModal = false,
    id,
    detail,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                ...(detail?.id ? {} : { code: Number(values?.code) }),
                name: values?.name,
                logo_url: values?.logo_url,
                website: values?.website,
                contact_name: values?.contact_name,
                contact_phone: values?.contact_phone,
                contact_email: values?.contact_email,
            };
            const response: any = detail?.id ? await putMedia(detail?.id, data) : await postMedia(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        code: Yup.number().required(`${t("This is required")}`).typeError(`${t("This must type number")}`),
        name: Yup.string().required(`${t("This is required")}`),
        logo_url: Yup.string().required(`${t("This is required")}`),
        website: Yup.string().required(`${t("This is required")}`),
        contact_name: Yup.string().required(`${t("This is required")}`),
        contact_phone: Yup.string().matches(/^\d+$/, `${t('Phone number should only contain digits')}`).required(`${t("This is required")}`),
        contact_email: Yup.string().email(`${t('Please enter a valid email address')}`).required(`${t('This is required')}`),
    });

    const formik = useFormik({
        initialValues: {
            code: '',
            name: '',
            logo_url: '',
            website: '',
            contact_name: '',
            contact_phone: '',
            contact_email: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    });


    const handleSetValueForm = (valueDefault: any) => {
        const v_code = valueDefault?.code;
        const v_name = valueDefault?.name;
        const v_logo_url = valueDefault?.logo_url;
        const v_website = valueDefault?.website;
        const v_contact_name = valueDefault?.contact_name;
        const v_contact_phone = valueDefault?.contact_phone;
        const v_contact_email = valueDefault?.contact_email;

        formik.setFieldValue("code", v_code);
        formik.setFieldValue("name", v_name);
        formik.setFieldValue("logo_url", v_logo_url);
        formik.setFieldValue("website", v_website);
        formik.setFieldValue("contact_name", v_contact_name);
        formik.setFieldValue("contact_phone", v_contact_phone);
        formik.setFieldValue("contact_email", v_contact_email);
    };


    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <Row className="g-5 mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Row className="mt-2">
                                                <Col lg={4} className="pe-0">
                                                    {!!formik?.values?.logo_url ? <img className="avatar-md rounded object-fit-contain" style={{ height: '72px', background: '#eaeaea' }} alt={formik?.values?.name} src={formik?.values?.logo_url} /> : <SVGImageDefault style={{ height: '72px' }} />}
                                                </Col>
                                                <Col lg={8} className="px-0">
                                                    <Row>
                                                        <Col lg={12} className="pe-0">
                                                            <div>
                                                                <label className="form-label pt-1">
                                                                    {t("Code")}
                                                                    <span className="text-danger">*</span>
                                                                </label>
                                                                <Input
                                                                    type="text"
                                                                    id="code"
                                                                    name="code"
                                                                    autocomplete={false}
                                                                    value={formik?.values?.code}
                                                                    disabled={!!detail?.id}
                                                                    placeholder={`${t("Code")}...`}
                                                                    onChange={(event: any) => formik.setFieldValue("code", event?.target?.value || "")}
                                                                />
                                                                {formik.touched.code && formik.errors.code ? (
                                                                    <div className="text-danger mt-2">
                                                                        {formik.errors.code}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Media Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={formik?.values?.name}
                                                        placeholder={`${t("Media Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Logo URL")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="logo_url"
                                                        name="logo_url"
                                                        autocomplete={false}
                                                        value={formik?.values?.logo_url}
                                                        placeholder={`${t("Logo URL")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("logo_url", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.logo_url && formik.errors.logo_url ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.logo_url}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Website")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="website"
                                                        name="website"
                                                        autocomplete={false}
                                                        value={formik?.values?.website}
                                                        placeholder={`${t("Website")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("website", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.website && formik.errors.website ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.website}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_name"
                                                        name="contact_name"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_name}
                                                        placeholder={`${t("Contact Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_name && formik.errors.contact_name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Phone")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_phone"
                                                        name="contact_phone"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_phone}
                                                        placeholder={`${t("Contact Phone")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_phone", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_phone && formik.errors.contact_phone ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_phone}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Contact Email")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="contact_email"
                                                        name="contact_email"
                                                        autocomplete={false}
                                                        value={formik?.values?.contact_email}
                                                        placeholder={`${t("Contact Email")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("contact_email", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.contact_email && formik.errors.contact_email ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.contact_email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Cancel")}
                                                    </button>
                                                    <button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '130px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Media") : t("Button Create Media")}</>}
                                                    </button>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>

        </React.Fragment >
    );
};

export default FormMedia;
