import { IInventory } from "api/types/_inventory";
import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import { STATUS_MEDIA_OPTIONS } from "helpers/constans";
import { formatLinkHTTPS } from "helpers/format";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { deleteInventory, getInventories as onGetInventories } from "../../../store/thunks";
import FormInventory from "./FormInventory";

const SORT_BY_DEFAULT = 'id';
const ORDER_BY_DEFAULT = 'DESC';
const MAX_WIDTH_SCREEN = 1800;
const ManagementInventory = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();

    const STATUS_MEDIA_OPTIONS_LANG = STATUS_MEDIA_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 30),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        name: withDefault(StringParam, ''),
        is_active: withDefault(StringParam, ''),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Management;

    const ManagementProperties = createSelector(
        selectLayoutState,
        (state) => ({
            inventories: state.inventories,
            isInventorySuccess: state.isInventorySuccess,
            isInventoryLoading: state.isInventoryLoading,
            error: state.error,
        })
    );

    const { inventories, isInventoryLoading } = useSelector(ManagementProperties);

    const [nameSearch, setNameSearch] = useState<string>(query?.name || "");

    const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_MEDIA_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.is_active))[0]);

    const [inventoryDetail, setInventoryDetail] = useState<IInventory | null>(null);

    const [idDelete, setIdDelete] = useState<number | null>(null);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    const [windowSize, setWindowSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

    useEffect(() => {
        dispatch(onGetInventories(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            name: nameSearch || undefined,
            is_active: statusSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        setQuery({
            name: undefined,
            is_active: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date()
        }, "push");
        setNameSearch("");
        setStatusSearch(null);
    };


    const handleRefresh = () => {
        setInventoryDetail((_prev) => null);
        resetData();
    }

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('#'),
                accessor: "stt",
                filterable: false,
                sortable: true,
                thWidth: 70,
                thClass: 'align-middle text-start',
                Cell: (cell: any) => {
                    return (<>
                        <div className="text-start" style={{ minWidth: '50px' }}>{((query?.page - 1) * (query?.limit)) + (cell?.cell?.row?.index + 1)}</div>
                    </>)
                },
            },
            {
                Header: t('Inventory Name'),
                accessor: "name",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="d-flex align-items-center" style={{ minWidth: '170px' }}>
                                <div className="flex-shrink-0 me-2">
                                    <img src={item?.logo_url || 'https://api-node.themesbrand.website/images/users/avatar-2.jpg'} alt={item?.name} className="avatar-sm object-fit-contain" style={{ backgroundColor: 'rgb(234 234 234 / 49%)', borderRadius: '5px' }} />
                                </div>
                                <CopyWrapper contentCopy={item?.name} >
                                    {item?.name}
                                </CopyWrapper>
                            </div>
                        </>
                    )
                },
            },
            {
                Header: t('Media'),
                accessor: "media",
                filterable: false,
                sortable: false,
                Cell: (cell: any) => (
                    <>
                        <Link className='text-normal' to={formatLinkHTTPS(cell?.value)} target="_blank">
                            {cell?.value}
                        </Link>
                    </>
                ),
            },
            {
                Header: t('Status'),
                accessor: "is_active",
                filterable: false,
                sortable: false,
                thWidth: 100,
                thClass: 'text-center',
                Cell: (cell: any) => {
                    return (
                        <div className="text-center" style={{ minWidth: '80px' }}>
                            <span className={`rounded-pill badge bg-${['danger', 'success'][cell?.value] || 'secondary'}`}>{STATUS_MEDIA_OPTIONS_LANG?.find((item: any) => String(item?.value) === String(cell?.value))?.label}</span>
                        </div>
                    )
                },
            },
            {
                Header: t('Created at'),
                accessor: "created_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },

            {
                Header: t('Last update'),
                accessor: "updated_at",
                filterable: true,
                sortable: false,
                thWidth: 150,
                thClass: 'text-end',
                Cell: (cell: any) => {
                    const arrDate = String(cell?.value || '').split(' ');
                    return (
                        <div className="text-end" style={{ minWidth: '120px' }}>
                            <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
                        </div>
                    )
                },
            },
            {
                Header: t('Action'),
                thClass: 'text-center',
                thWidth: 150,
                Cell: (cell: any) => {
                    const item: any = cell?.row?.original;
                    return (
                        <div className="text-center" style={{ minWidth: '120px' }}>
                            <ul className="list-inline justify-content-center hstack gap-2 mb-0">
                                <TooltipCustom
                                    title={t('Button Detail Inventory')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-secondary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setInventoryDetail((_prev) => item);
                                            }}>
                                            <i className="ri-eye-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                <TooltipCustom
                                    title={t('Button Update Inventory')}
                                    id={`update-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item edit " title="Edit">
                                        <Link className="btn btn-sm btn-soft-primary d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setInventoryDetail((_prev) => item);
                                            }}>
                                            <i className="ri-pencil-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                                <TooltipCustom
                                    title={t('Button Delete Inventory')}
                                    id={`detail-cp-${item?.id}`}
                                >
                                    <li className="list-inline-item" title="Remove">
                                        <Link className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn" to="#"
                                            onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                                        >
                                            <i className="ri-delete-bin-5-fill"></i>
                                        </Link>
                                    </li>
                                </TooltipCustom>
                            </ul>
                        </div>
                    );
                },
            },
        ],
        [i18n?.language, userPermissions, JSON.stringify(query), STATUS_MEDIA_OPTIONS_LANG]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleCallAllOption = async () => {
        try {
            const []: any = await Promise.all([]);

        } catch (error: any) {
            return error;
        }
    };

    // Begin::Delete
    const handleConfirmDelete = (item: IInventory) => {
        setIdDelete((_prev) => item?.id);
    };

    const handleActionDelete = async () => {
        if (!idDelete) { return; };
        try {
            setIsConfirmLoading((_prev) => true);
            const response: any = await deleteInventory(idDelete);
            if (response?.code === 200) {
                dispatch(onGetInventories(query));
                setIsConfirmLoading((_prev) => false);
                setIdDelete((_prev) => null);
                toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
            } else {
                setIsConfirmLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsConfirmLoading((_prev) => false);
            toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const onCloseConfirmClick = () => {
        setIdDelete((_prev) => null);
    }
    // End::Delete

    useEffect(() => {
        handleCallAllOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = `${t("Inventory Management")} - ${t("Advertising Management")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Inventory Management')} pageTitle={t('Advertising Management')} />
                    <Row>
                        <div className="text-center my-4">
                            {t('Is in the process of development')}
                        </div>
                        {/* <Col sm={12} md={((inventoryDetail !== null) && (windowSize?.width >= MAX_WIDTH_SCREEN)) ? 8 : 12}>
                            <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                <CardHeader className="border-0">
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">
                                                    <span className="me-2">{t('Total')}:</span>
                                                    <CountUp
                                                        start={0}
                                                        end={inventories?.pagination?.total || 0}
                                                        duration={1}
                                                        className="text-primary"
                                                    />
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-auto">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-success add-btn rounded-pill"
                                                    id="create-btn"
                                                    onClick={() => setInventoryDetail((_prev) => ({} as IInventory))}
                                                >
                                                    <i className="ri-add-line align-bottom me-1"></i>
                                                    {t('Button Create Inventory')}
                                                </button>
                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                    <Row className="g-4 align-items-center mt-0">
                                        <Col sm={6} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                            <LabelWrapper label={t('Inventory')} isShow={!!nameSearch}>
                                                <Input
                                                    type="text"
                                                    className="form-control search"
                                                    placeholder={`${t('Inventory')}...`}
                                                    value={nameSearch}
                                                    onChange={(e) => setNameSearch(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </LabelWrapper>
                                        </Col>
                                        <Col sm={6} md={6} xl={3} xxl={2} className='mt-3 mt-md-2'>
                                            <LabelWrapper label={t('Status')} isShow={!!statusSearch?.value}>
                                                <DropdownStatus
                                                    name="status"
                                                    dataList={STATUS_MEDIA_OPTIONS_LANG || []}
                                                    placeholder={`${t("Status")}...`}
                                                    className="dropdown-status-rounded"
                                                    classNamePrefix="name-prefix"
                                                    initialValue={statusSearch || null}
                                                    onChangeSelect={(e: any) => setStatusSearch(e)}
                                                    isHasOptionAll={true}
                                                    optionAll={{ label: t('All Status'), value: '' }}
                                                    colors={['danger', 'success']}
                                                />
                                            </LabelWrapper>
                                        </Col>
                                        <Col sm={12} md={12} xl={6} xxl={8} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-3 mt-md-2">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary me-2 "
                                                    onClick={searchData}
                                                    disabled={isInventoryLoading}
                                                >
                                                    <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                    {t('Button Search')}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary fs-14"
                                                    onClick={resetData}
                                                >
                                                    <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                    {t('Button Reset')}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-3">
                                    <TableContainer
                                        className="custom-header-css"
                                        divClass="table-responsive table-card"
                                        tableClass="align-middle table-bordered-dashed"
                                        theadClass="table-light text-muted"
                                        columns={columns}
                                        data={inventories?.list?.length ? inventories?.list : []}
                                        customPageSize={query.limit}
                                        customPageIndex={query.page - 1}
                                        totalRecords={inventories?.pagination?.total}
                                        customPageCount={Math.ceil(Number(inventories?.pagination?.total) / Number(inventories?.pagination?.limit))}
                                        handleChangePage={handleChangePage}
                                        manualSorting={true}
                                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                        handleChangeSorting={handleChangeSorting}
                                        isLoading={isInventoryLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col> */}
                        {((windowSize?.width >= MAX_WIDTH_SCREEN) && (inventoryDetail !== null)) && (
                            <Col md={4} >
                                <Card id="customerList" style={{ position: 'sticky', top: '80px', boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                                    <CardHeader >
                                        <div className="d-flex mb-3">
                                            <div className="flex-grow-1">
                                                <h5 className="fs-16 mb-1">{!!inventoryDetail?.id ? t('Button Update Inventory') : t('Button Create Inventory')}</h5>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <Link to="#" className="text-decoration-underline" onClick={() => setInventoryDetail((_prev) => null)}>
                                                    {t('Button Close')}
                                                </Link>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <FormInventory detail={inventoryDetail} triggerRefresh={handleRefresh} handleClose={() => setInventoryDetail((_prev) => null)} />
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                        {(windowSize?.width < MAX_WIDTH_SCREEN) && (<Modal
                            isOpen={inventoryDetail !== null}
                            centered={true}
                            size="md"
                            toggle={() => setInventoryDetail((_prev) => null)}
                            keyboard={true}
                        >
                            <ModalHeader toggle={() => setInventoryDetail((_prev) => null)}>
                                {!!inventoryDetail?.id ? t('Button Update Inventory') : t('Button Create Inventory')}
                            </ModalHeader>
                            <ModalBody className="">
                                <FormInventory detail={inventoryDetail} triggerRefresh={handleRefresh} handleClose={() => setInventoryDetail((_prev) => null)} />
                            </ModalBody>
                        </Modal>)}
                    </Row>
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
            <ModalConfirm
                header={t('Button Delete Inventory')}
                isOpen={idDelete !== null}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmClick}
                onConfirm={handleActionDelete}
            />
        </React.Fragment>
    );
};

export default ManagementInventory;