const ROLES_FOR_APP:any = {    
    DASHBOARD_VIEW: "view",

    MANAGEMENT_MEDIA: 'managements-media',
    MANAGEMENT_INVENTORY: 'managements-inventory',
  
    REPORT_MEDIA: 'reports-media',
    REPORT_INVENTORY: 'reports-inventory',

    TRACKING_LOG: 'tracking-log',

};

const isHavePermissionRole = (keyRoles: string | string[], permissions: {id: string | number, name: string}[] = []):boolean => {
    const roles =  typeof keyRoles === 'string' ? [keyRoles] : keyRoles;
    return  true; // permissions?.some(item => roles?.includes(item.name));
};

export { ROLES_FOR_APP, isHavePermissionRole };