import { IInventory } from "api/types/_inventory";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Card,
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";
import { postInventory, putInventory, uploadImage } from "store/thunks";
import * as Yup from "yup";


export interface Props {
    isModal?: boolean;
    id?: number;
    detail?: IInventory | null;
    triggerRefresh?: () => void;
    handleClose?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);


const modules = {
    toolbar: [
        // ["bold", "italic", "underline", "blockquote"],
        [{ align: "right" }, { align: "center" }, { align: "justify" }]
    ]
};
const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font"
];

function getImageNameFromUrl(url: string) {
    const parts = url.split('/');
    return parts[parts.length - 1];
}

const FormInventory = ({
    isModal = false,
    detail,
    triggerRefresh,
    handleClose,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const refContentScript = useRef<any>(null);

    function handleAcceptedFiles(files: any) {
        files.map((file: any, i: number) =>
            Object.assign(file, {
                id: Number(+ new Date()) + i,
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        formik.setFieldValue("images_upload", files);
    }

    function handleRemoveImageUpload(idImage: number) {
        const files = (formik?.values?.images_upload || [])?.filter((file: any, i: number) => (file?.id !== idImage));
        formik.setFieldValue("images_upload", files);
    }

    function handleRemoveImage(image: string) {
        const images = (formik?.values?.images || [])?.filter((img: any, i: number) => (img !== image));
        formik.setFieldValue("images", images);
    }

    function formatBytes(bytes: any, decimals = 2) {
        /* Formats the size */
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    async function handleUploadFiles(files: File[] = []) {
        const maxAllowedSize = 20 * 1024 * 1024;
        const uploadPromises = files.map(file => {
            return new Promise(async (resolve, reject) => {
                if (file.size > maxAllowedSize) {
                    toast(`${t('Please choose a file with a size not exceeding 20 MB.')}`, CONFIG_OPTION_TOAST_ERROR);
                    return reject(new Error('File size exceeds the allowed limit.'));
                }
                const formData = new FormData();
                formData.append('files', file);
                try {
                    // Upload file
                    const response: any = await uploadImage(formData);
                    if (response?.code === 200) {
                        // Get the uploaded URL from the response
                        const uploadedUrl = response?.data[0]?.url;
                        // Invoke the callback with the uploaded URL
                        resolve({ uploadedUrl, file });
                    } else {
                        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
                        reject(new Error('Failed to upload file.'));
                    }
                } catch (error) {
                    console.error('Error uploading file:', error);
                    reject(error);
                }
            });
        });

        const results = await Promise.all(uploadPromises);
        return results?.map((result: any) => result?.uploadedUrl) || [];

    }

    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                name: values?.name,
                url: values?.url,
                script: values?.script,
                images: values?.images,
            };
            if (values?.images_upload && values?.images_upload?.length > 0) {
                const results = await handleUploadFiles(values?.images_upload);
                data['images'] = (values?.images || []).concat(results || []);
            }
            const response: any = detail?.id ? await putInventory(detail?.id, data) : await postInventory(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(`${t("Please enter the inventory name")}`),
        url: Yup.string().required(`${t("Please enter the URL")}`),
        script: Yup.string().required(`${t("Please enter the banner script")}`),
        images: Yup.array().of(Yup.string().required(`${t("Please upload the image")}`)),
        images_upload: Yup.array().of(
            Yup.mixed().test('is-image', `${t("Each file must be an image")}`, (value: any) => {
                return value && value.type.startsWith('image/');
            })
        ).when('images', (images, schema) => {
            return (!images || images?.filter((e) => !!e).length === 0) ? schema.min(1, `${t("At least one image must be uploaded")}`).required(`${t("At least one image must be uploaded")}`) : schema;
        }),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            url: "",
            script: "",
            images: undefined,
            images_upload: undefined
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const vName = valueDefault?.name;
        const vUrl = valueDefault?.url;
        const vScript = valueDefault?.script;
        const vImages = valueDefault?.images || [];
        formik.setFieldValue("name", vName);
        formik.setFieldValue("url", vUrl);
        formik.setFieldValue("script", vScript);
        formik.setFieldValue("images", vImages);
        formik.setFieldValue("images_upload", []);
    };

    useEffect(() => {
        setTimeout(() => {
            formik.setErrors({});
        }, 300);
        if (detail?.id) {
            handleSetValueForm(detail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <div id="leadsList">
                            <div className="pt-0">
                                <Row className="g-5 mt-2">
                                    <Col sm={12} className="mt-0">
                                        <div
                                            className="row g-3"
                                            style={{
                                                backgroundColor: "var(--vz-light)",
                                                padding: "10px 10px 20px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Inventory Name")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        autocomplete={false}
                                                        value={formik?.values?.name}
                                                        placeholder={`${t("Inventory Name")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("name", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("URL")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="url"
                                                        name="url"
                                                        autocomplete={false}
                                                        value={formik?.values?.url}
                                                        placeholder={`${t("URL")}...`}
                                                        onChange={(event: any) => formik.setFieldValue("url", event?.target?.value || "")}
                                                    />
                                                    {formik.touched.url && formik.errors.url ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.url}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Banner Script")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    {/* <EditorCustom
                                                        height='300px'
                                                        initialValue={formik?.values?.script || ''}
                                                        ref={refContentScript}
                                                        toolbar="blocks | aligncenter alignjustify alignleft alignnone alignright"
                                                    /> */}
                                                    <ReactQuill
                                                        theme="snow"
                                                        modules={modules}
                                                        formats={formats}
                                                        style={{
                                                            backgroundColor: 'var(--vz-input-bg-custom)',
                                                            borderRadius: 'var(--vz-border-radius)',
                                                        }}
                                                        className="quill-container-template show-border height-auto"
                                                        defaultValue={formik?.values?.script}
                                                        onChange={(event: any) => formik.setFieldValue('script', event === '<p><br></p>' ? '' : event)}
                                                    />
                                                    {formik.touched.script && formik.errors.script ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.script}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div>
                                                    <label className="form-label">
                                                        {t("Media")}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <Dropzone
                                                        accept={{ "image/*": [".png", ".gif", ".jpeg", ".jpg"] }}
                                                        onDrop={acceptedFiles => {
                                                            handleAcceptedFiles(acceptedFiles);
                                                        }}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div className="dropzone dz-clickable" style={{ minHeight: '50px', borderRadius: 'var(--vz-border-radius)' }}>
                                                                <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                >
                                                                    <div className="mb-1">
                                                                        <i className="display-4 text-muted ri-upload-cloud-2-line" />
                                                                    </div>
                                                                    <h6 className="mb-3">{t('Drop files here or click to upload')}</h6>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    {formik.touched.images_upload && formik.errors.images_upload ? (
                                                        <div className="text-danger mt-2">
                                                            {formik.errors.images_upload}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="list-unstyled mb-0" id="file-previews">
                                                    {(formik?.values?.images || []).map((img: any, i: any) => {
                                                        return (
                                                            <Card
                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete position-relative"
                                                                key={i + "-file"}
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center ">
                                                                        <Col className="col-auto">
                                                                            <img
                                                                                data-dz-thumbnail=""
                                                                                height="80"
                                                                                className="avatar-sm rounded bg-light"
                                                                                alt={getImageNameFromUrl(img)}
                                                                                src={img}
                                                                            />
                                                                        </Col>
                                                                        <Col className="pe-5">
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold"
                                                                            >
                                                                                {getImageNameFromUrl(img)}
                                                                            </Link>
                                                                        </Col>
                                                                        <button
                                                                            className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn fs-14 position-absolute"
                                                                            color="light"
                                                                            type="button"
                                                                            style={{ width: '32px', top: 5, right: 5, borderRadius: 7 }}
                                                                            onClick={() => handleRemoveImage(img)}
                                                                        >
                                                                            <i className="ri-delete-bin-5-fill"></i>
                                                                        </button>
                                                                    </Row>
                                                                </div>
                                                            </Card>
                                                        );
                                                    })}
                                                    {(formik?.values?.images_upload || []).map((f: any, i: any) => {
                                                        return (
                                                            <Card
                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete position-relative"
                                                                key={i + "-file"}
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center ">
                                                                        <Col className="col-auto">
                                                                            <img
                                                                                data-dz-thumbnail=""
                                                                                height="80"
                                                                                className="avatar-sm rounded bg-light"
                                                                                alt={f.name}
                                                                                src={f.preview}
                                                                            />
                                                                        </Col>
                                                                        <Col className="pe-5">
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold"
                                                                            >
                                                                                {f.name}
                                                                            </Link>
                                                                            <p className="mb-0">
                                                                                <strong>{f.formattedSize}</strong>
                                                                            </p>
                                                                        </Col>
                                                                        <button
                                                                            className="btn btn-sm btn-soft-danger d-inline-block edit-item-btn fs-14 position-absolute"
                                                                            color="light"
                                                                            type="button"
                                                                            style={{ width: '32px', top: 5, right: 5, borderRadius: 7 }}
                                                                            onClick={() => handleRemoveImageUpload(f.id)}
                                                                        >
                                                                            <i className="ri-delete-bin-5-fill"></i>
                                                                        </button>
                                                                    </Row>
                                                                </div>
                                                            </Card>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                                                    <button
                                                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                                                        color="light"
                                                        type="button"
                                                        disabled={isLoading}
                                                        style={{ width: '100px' }}
                                                        onClick={() => { handleClose && handleClose() }}
                                                    >
                                                        {t("Button Cancel")}
                                                    </button>
                                                    <button
                                                        className="btn btn-primary rounded-pill fs-14"
                                                        color="success"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        style={{ width: '130px' }}
                                                    >
                                                        {isLoading ? (
                                                            <Spinner size="sm" className="me-2"></Spinner>
                                                        ) :
                                                            <> {detail?.id ? t("Button Update Inventory") : t("Button Create Inventory")}</>}
                                                    </button>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment >
    );
};

export default FormInventory;
