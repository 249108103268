import { createSlice } from "@reduxjs/toolkit";


export interface IState {

  error: any,
};

export const initialState: IState = {

  error: {},
};

const TrackingSlice = createSlice({
  name: "Tracking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    
  },
});

export default TrackingSlice.reducer;