import trackingApi from "api/trackingApi";


export const getTrackingLogsByNormal =  async (params: any = {}) => {
  try{
    const response = await trackingApi.trackingLogs(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const getTrackingSummariesLogs =  async (params: any = {}) => {
  try{
    const response = await trackingApi.trackingSummariesLogs(params)
    return response;
  }catch (error) {
    return error;
  }
};