import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IInventory, IUploadImage } from "./types/_inventory";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/inventories';
const inventoryApi = {
  inventories(params: any):Promise<AxiosResponse<ResponseData<IInventory[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  getInventory(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IInventory>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postInventory(dataForm: any) : Promise<AxiosResponse<ResponseData<IInventory>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putInventory(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IInventory>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteInventory(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IInventory>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  uploadImage(dataForm: any) : Promise<AxiosResponse<IUploadImage[], any>>  {
    const url = `upload/r2-files`;
    // const formData = new FormData();
    // formData.append('file', file);
    return api.post(url, dataForm, {
      timeout: 1000 * 60 * 10,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
export default inventoryApi
